import { action, computed, observable, runInAction } from "mobx";
// import { enqueueSnackbar } from "notistack";
import request from "superagent";
import Translate from "../../react-shared/Translations";
import { PageState } from "../../react-shared/interfaces";

const fromDate: Date = new Date();
fromDate.setDate(0);
fromDate.setDate(1);

const tooDate = new Date();
tooDate.setDate(0);

const today = new Date();

interface Log {
  created: Date;
  type: any;
  logMessage: any;
}

interface Configuration {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
}

interface Trigger {
  id: any;
  name: any;
  status: TriggerState;
  prevExecutionTime: any;
  nextExecutionTime: any;
}

export type TriggerState = "complete" | "normal" | "paused" | "none" | "error" | "blocked";
export type Context = "RUN" | "SCHEDULE" | "LOGS" | "CONFIGURATIONS";

class IntegrationPlatformStore {
  @observable configurations: Configuration[] = [];
  @observable triggers: Trigger[] = [];
  @observable logs?: Log[] = [];
  @observable EventLogTabState: PageState = "OK";
  @observable ManualRunTabState: PageState = "OK";
  @observable ScheduleTabState: PageState = "OK";
  @observable ConfigurationLoadState: PageState = "OK";
  @observable primaryStateText: string = "";
  @observable secondaryStateText: string = "";

  @observable runFromDate: string = fromDate.toISOString().split("T")[0];
  @observable initialRunFromDate: string = fromDate.toISOString().split("T")[0];
  @observable runToDate: string = tooDate.toISOString().split("T")[0];
  @observable initialRunToDate: string = tooDate.toISOString().split("T")[0];
  @observable logsFromDate: string = today.toISOString().split("T")[0];
  @observable logsToDate: string = today.toISOString().split("T")[0];
  @observable selectedConfigurationId: string = "";

  @computed
  get getSelectedConfig(): Configuration | undefined {
    return this.configurations?.find((p) => p.id == this.selectedConfigurationId);
  }

  setStateOfPage = ({ context, state }: { context: Context; state: PageState }) => {
    this.primaryStateText = "";
    this.secondaryStateText = "";
    switch (state) {
      case "OK":
        switch (context) {
          case "CONFIGURATIONS":
            this.ConfigurationLoadState = state;
            break;
          case "LOGS":
            this.EventLogTabState = state;
            break;
          case "RUN":
            this.ManualRunTabState = state;
            break;
          case "SCHEDULE":
            this.ScheduleTabState = state;
            break;
        }
        break;
      case "LOADING":
        switch (context) {
          case "CONFIGURATIONS":
            this.primaryStateText = Translate("IntegrationPlatform_Loading");
            this.secondaryStateText = Translate("IntegrationPlatform_LoadingConfigs");
            this.ConfigurationLoadState = state;
            break;
          case "LOGS":
            this.primaryStateText = Translate("IntegrationPlatform_Loading");
            this.EventLogTabState = state;
            break;
          case "RUN":
            this.primaryStateText = Translate("IntegrationPlatform_Execution_STARTING");
            this.secondaryStateText = this.getSelectedConfig?.name ?? "";
            this.ManualRunTabState = state;
            break;
          case "SCHEDULE":
            this.primaryStateText = Translate("IntegrationPlatform_Loading");
            this.ScheduleTabState = state;

            break;
        }
        break;
      case "NODATA":
        switch (context) {
          case "CONFIGURATIONS":
            this.ConfigurationLoadState = state;
            this.primaryStateText = Translate("IntegrationPlatform_NoConfiguration");
            this.secondaryStateText = "";
            break;
          case "LOGS":
            this.EventLogTabState = state;
            break;
          case "RUN":
            this.ManualRunTabState = state;
            break;
          case "SCHEDULE":
            this.ScheduleTabState = state;
            break;
        }
        break;
      case "SUCCESS":
        switch (context) {
          case "RUN":
            this.ManualRunTabState = state;
            this.primaryStateText = Translate("IntegrationPlatform_Execution_SUCCESS");
            this.secondaryStateText = this.getSelectedConfig?.name ?? "";
            break;
        }
        break;
      case "ERROR":
        switch (context) {
          case "CONFIGURATIONS":
            this.ConfigurationLoadState = state;
            this.primaryStateText = Translate("IntegrationPlatform_APIError");
            this.secondaryStateText = "";
            break;
          case "LOGS":
            this.EventLogTabState = state;
            this.primaryStateText = Translate("IntegrationPlatform_APIError");
            this.secondaryStateText = "";
            break;
          case "RUN":
            this.ManualRunTabState = state;
            this.primaryStateText = Translate("IntegrationPlatform_Execution_ERROR");
            this.secondaryStateText = "";
            break;
          case "SCHEDULE":
            this.ScheduleTabState = state;
            this.primaryStateText = Translate("IntegrationPlatform_APIError");
            this.secondaryStateText = "";
            break;
        }
    }
  };

  @action
  loadLogs = async () => {
    await runInAction(async () => {
      this.setStateOfPage({
        context: "LOGS",
        state: "LOADING",
      });
      try {
        await request
          .post(`/mashie/IntegrationPlatform/logs`)
          .send({
            from: this.logsFromDate,
            to: this.logsToDate,
            selectedConfigurationId: this.selectedConfigurationId,
          })
          .on("response", (response: any) => {
            this.logs = JSON.parse(response.text);
          })
          .on("error", (_error) => {
            this.setStateOfPage({
              context: "LOGS",
              state: "ERROR",
            });
            // enqueueSnackbar(error, { variant: "error" });
          })
          .then(() => {
            this.EventLogTabState = "OK";
          });
      } catch (error) {
        this.setStateOfPage({
          context: "LOGS",
          state: "ERROR",
        });
      }
    });
  };

  @action
  manualRun = async () => {
    // let confirmationMessage = Translate("IntegrationPlatform_ManualRunConfirmation");
    // if (this.runFromDate != this.initialRunFromDate || this.runToDate != this.initialRunToDate) {
    //   confirmationMessage = confirmationMessage + "\n\n" + Translate("IntegrationPlatform_ManualRunConfirmationNote");
    // }
    this.setStateOfPage({
      context: "RUN",
      state: "LOADING",
    });
    const payload = {
      from: this.runFromDate,
      to: this.runToDate,
      selectedConfigurationId: this.selectedConfigurationId,
    };
    runInAction(async () => {
      try {
        await request
          .post(`/mashie/IntegrationPlatform/manual-run`)
          .send(payload)
          .on("response", () => {})
          .on("error", (_error) => {
            this.setStateOfPage({
              context: "RUN",
              state: "ERROR",
            });
          })
          .then(() => {
            this.setStateOfPage({
              context: "RUN",
              state: "SUCCESS",
            });

            setTimeout(() => {
              this.ManualRunTabState = "OK";
            }, 3000);
          });
      } catch (error) {}
    });
  };

  toggleTrigger = (trigger: Trigger) => {
    try {
      this.ScheduleTabState = "LOADING";
      request
        .post(`/mashie/IntegrationPlatform/toggle-trigger`)
        .send(trigger)
        .on("response", (response: request.Response) => {
          const body = response.body as Trigger[];
          let index = this.triggers.findIndex((p) => p.id == trigger.id);
          let newTrigger = body.find((p) => p.id == trigger.id) ?? ({} as Trigger);
          this.triggers[index] = newTrigger;
        })
        .on("error", (_error) => {
          // enqueueSnackbar(error, { variant: "error" });
        })
        .then(() => {});
    } catch (error) {}
  };

  loadConfiguration = async (type: string) => {
    this.setStateOfPage({
      context: "CONFIGURATIONS",
      state: "LOADING",
    });

    await runInAction(async () => {
      try {
        await request
          .get(`/mashie/IntegrationPlatform/${type}`)
          .on("error", (_error) => {
            this.setStateOfPage({
              context: "CONFIGURATIONS",
              state: "ERROR",
            });
            // enqueueSnackbar(error, { variant: "error" });
          })

          .on("response", (response: request.Response) => {
            const body = response.body;
            if (!body) return;
            this.configurations = body.configurations;
            this.triggers = body.triggers;
            // this.logs = body.logs;
            this.selectedConfigurationId = body.selectedConfigurationId;
            let configuration = this.configurations.find(x => x.id == body.selectedConfigurationId);
            if (configuration) {
              this.runFromDate = configuration.startDate.split("T")[0];
              this.runToDate = configuration.endDate.split("T")[0];
            }
          })
          .then(async () => {
            this.configurations.length > 0
              ? ((this.ConfigurationLoadState = "OK"), (this.ManualRunTabState = "OK"))
              : this.setStateOfPage({
                  context: "CONFIGURATIONS",
                  state: "NODATA",
                });

            await this.loadLogs();
          });
      } catch (error) {}
    });
  };

  convertUTCDateToLocalDate = (date: any) => {
    var utcDate = new Date(date);
    var newDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000);
    var offset = utcDate.getTimezoneOffset() / 60;
    var hours = utcDate.getHours();
    newDate.setHours(hours - offset);
    return newDate.toLocaleString();
  };

  handleConfigValueChange = (id: string) => {
    this.loadConfiguration("configuration/" + id);
  };

  handleRunFromDateValueChange = (runFromDate: string) => {
    if (new Date(this.runToDate) < new Date(runFromDate)) {
      this.runToDate = runFromDate;
    }
    this.runFromDate = runFromDate;
  };

  handleRunToDateValueChange = (runToDate: string) => {
    if (new Date(runToDate) < new Date(this.runFromDate)) {
      this.runFromDate = runToDate;
    }
    this.runToDate = runToDate;
  };

  handleLogsFromDateValueChange = (logsFromDate: string) => {
    if (new Date(this.logsToDate) < new Date(logsFromDate)) {
      this.logsToDate = logsFromDate;
    }
    this.logsFromDate = logsFromDate;
  };

  handleLogsToDateValueChange = (logsToDate: string) => {
    if (new Date(logsToDate) < new Date(this.logsFromDate)) {
      this.logsFromDate = logsToDate;
    }
    this.logsToDate = logsToDate;
  };
}

const store = new IntegrationPlatformStore();

export default store;

export const translateTriggerState = (state: TriggerState) => {
  var translation;
  switch (state.toLowerCase()) {
    case "complete":
      translation = Translate("IntegrationPlatform_state_COMPLETE");
      break;
    case "normal":
      translation = Translate("IntegrationPlatform_state_NORMAL");
      break;
    case "paused":
      translation = Translate("IntegrationPlatform_state_PAUSED");
      break;
    case "none":
      translation = Translate("IntegrationPlatform_state_NONE");
      break;
    case "error":
      translation = Translate("IntegrationPlatform_status_ERROR");
      break;
    case "blocked":
      translation = Translate("IntegrationPlatform_state_BLOCKED");
      break;
    default:
      translation = state;
  }
  return translation;
};

export const translateLogType = (type: any) => {
  var translation;
  switch (type.toLowerCase()) {
    case "error":
      translation = Translate("IntegrationPlatform_status_ERROR");
      break;
    case "warning":
      translation = Translate("IntegrationPlatform_status_WARNING");
      break;
    case "information":
      translation = Translate("IntegrationPlatform_status_INFORMATION");
      break;
    case "trace":
      translation = Translate("IntegrationPlatform_status_TRACE");
      break;
    default:
      translation = type;
  }
  return translation;
};
